var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component"
  }, [_c('v-layout', {
    staticClass: "member-login",
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "background-layout"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ucardkorea/login-bg.png",
      "max-width": "100%",
      "height": "100%"
    }
  })], 1), _c('v-layout', {
    staticClass: "px-4 py-8",
    attrs: {
      "justify-center": "",
      "align-center": "",
      "fill-height": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "auto",
      "max-width": "480"
    }
  }, [_c('v-card', {
    staticClass: "ma-3",
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-card-title', {
    staticClass: "title justify-center py-6"
  }, [_vm._v("아이디 찾기")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-8"
  }, [_c('v-btn', {
    attrs: {
      "width": "100%",
      "x-large": "",
      "dark": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.find('username');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mt-1 mr-2"
  }, [_vm._v(_vm._s(_vm.mdiCertificateOutline))]), _vm._v("본인인증")], 1)], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "title justify-center py-6"
  }, [_vm._v("비밀번호 찾기")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-8"
  }, [_c('v-text-field', {
    attrs: {
      "label": "아이디",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "width": "100%",
      "x-large": "",
      "dark": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.find('password');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mt-1 mr-2"
  }, [_vm._v(_vm._s(_vm.mdiCertificateOutline))]), _vm._v("본인인증")], 1)], 1)], 1)], 1)], 1)], 1), _c('kcp-cert', {
    ref: "kcpCert"
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }