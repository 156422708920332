<template>
    <component :is="template">
        <v-layout fill-height class="member-login">
            <v-responsive height="100%">
                <div class="background-layout">
                    <v-img src="/images/ucardkorea/login-bg.png" max-width="100%" height="100%" />
                </div>
                <v-layout justify-center align-center fill-height class="px-4 py-8">
                    <v-responsive width="100%" height="auto" max-width="480" >
                        <v-card rounded="lg" class="ma-3">
                            <v-card-title class="title justify-center py-6">아이디 찾기</v-card-title>
                            <v-divider/>
                            <v-card-text class="pa-8">
                                <v-btn width="100%" x-large dark color="primary" @click="find('username')"><v-icon class="mt-1 mr-2">{{ mdiCertificateOutline }}</v-icon>본인인증</v-btn>
                            </v-card-text>
                            <v-divider/>
                            <v-card-title class="title justify-center py-6">비밀번호 찾기</v-card-title>
                            <v-divider/>
                            <v-card-text class="pa-8">
                                <v-text-field v-model="username" label="아이디" placeholder=" " hide-details />
                                <v-btn width="100%" x-large dark color="primary" class="mt-4" @click="find('password')"><v-icon class="mt-1 mr-2">{{ mdiCertificateOutline }}</v-icon>본인인증</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-responsive>
                </v-layout>
            </v-responsive>

            <kcp-cert ref="kcpCert" />
        </v-layout>
    </component>
</template>
<script>
import api from "@/api";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import { mdiCertificateOutline } from "@mdi/js";
export default {
    components: {
        VPasswordField,
        KcpCert
    },
    data(){
        return {
            mdiCertificateOutline,

            username: null,
        };
    },
    methods: {
        find(type){
            this.$refs.kcpCert.$off("input");
            switch(type){
                case "username": {
                    this.$refs.kcpCert.$on("input", async (payload) => {
                        console.log({ payload });
                        let { username } = await api.v1.members.repair({ type: "username", _cert: payload._cert });
                        alert(`회원님의 아이디는 ${username} 입니다`);
                        this.username = username;
                    });
                    this.$refs.kcpCert.open();
                    break;
                }
                case "password": {
                    if(this.validate()){
                        this.$refs.kcpCert.$on("input", async (payload) => {
                            await api.v1.members.repair({ type: "password", username: this.username, _cert: payload._cert });
                            alert("가입하신 연락처로 임시비밀번호가 발송되었습니다");
                        });
                        this.$refs.kcpCert.open();
                    }
                    break;
                }
            }
        },
        validate(){
            try{
                if(!this.username) throw new Error("아이디를 입력해주세요");
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
    },
    computed: {
        template(){
            return () => import(`@/templates/${this.$theme.pages.default}`);
        }
    }
}
</script>
<style scoped>
.member-login .title {
    font-size: 22px !important;
    font-weight: 600;
}
.member-login .background-layout {
    position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0; bottom: 0;
}
</style>